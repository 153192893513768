import React, { useContext, useEffect, useState } from 'react';
import { Box, Container, Typography } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import { identifyPackagesToReceive, movePackage } from '../../api-rest';
import getUserType from '../../app/access-control/access-control-service';
import { useDistributionCenter } from '../../app/access-control/distribution-center-provider';
import { PackageReader } from '../../app/components/barcode-readers';
import HeaderWithCancel from '../../app/components/header-with-cancel';
import { PACKAGE_ORIGIN } from '../../app/enums';
import handleRestAPIError from '../../app/utils/rest-api-request';
import {
  ACTIVITY,
  COGNITO_DISTRIBUTION_CENTER,
  HISTORY_ACTIONS,
  OPERATIONAL_PROCESS,
  ROUTES,
  SWITCHES,
  UNIFIED_RECEIVE_UNIT_LOAD_SUFFIX
} from '../../constants';
import { playErrorBeep, playSuccessBeep } from '../../sounds';
import { ReceivingProcessContext } from './receiving-process-context';
import { ActivityTrackingContext } from '../../app/activity-tracking/activity-tracking-provider';
import showSnackbar from '../../app/components/snackbar/snackbar-container';
import { useFeature } from '../../app/hooks/use-feature';
import { ReactComponent as EmptySearch } from '../../assets/images/empty-search.svg';
import HeaderWithReturn from '../../app/components/header-with-return';

/**
 * Wrapper to a PackageReader that fetches ReceiveInfo
 *
 * @callback onSuccessCallback called when Receive Info is successfully fetched
 * @callback onErrorCallback called when and error happens while fetching Receive Info
 *
 * @param {function} onSuccessCallback -
 * @param {function} onErrorCallback -
 *
 */
export function ReceiveInfoReader({
  onSuccessCallback,
  onErrorCallback,
  onChange,
  placeholder
}) {
  const [loading, setLoading] = useState(false);

  const { trackStart } = useContext(ActivityTrackingContext);

  const enableFilterByTrackingKey = useFeature(
    SWITCHES.enableFilterByTrackingKey
  );

  const handleRead = async barcode => {
    setLoading(true);

    trackStart(
      OPERATIONAL_PROCESS.BEEP_LATENCY,
      ACTIVITY.INITIAL_IDENTIFICATION_BEEP
    );

    const response = await identifyPackagesToReceive(barcode).catch(err =>
      handleRestAPIError(err, errorMsg => onErrorCallback(errorMsg))
    );

    if (!response) {
      setLoading(false);
      return;
    }

    let receivedPackage = (response.data.missingPackages || []).filter(
      pack => pack.barcode === barcode
    )[0];
    if (!receivedPackage && enableFilterByTrackingKey) {
      // eslint-disable-next-line prefer-destructuring
      receivedPackage = (response.data.missingPackages || []).filter(
        pack => pack.trackingKey === barcode
      )[0];
    }

    const unitLoadToReceive = (response.data.remainingUnitLoads || []).filter(
      unitLoad => unitLoad.licensePlate === barcode
    )[0];

    await onSuccessCallback({
      responsePackage: receivedPackage,
      senderInfo: response.data.senderInfo || {},
      incomingPackages: response.data.missingPackages || [],
      unitLoadToReceive,
      incomingUnitLoads: response.data.remainingUnitLoads || [],
      origin: response.data.origin,
      barcode
    });

    setLoading(false);
  };

  return (
    <Box>
      <Box my={2.5}>
        <PackageReader
          onRead={handleRead}
          onChange={onChange}
          loading={loading}
          notes="Bipe na tela de recebimento"
          placeholder={placeholder}
        />
      </Box>
    </Box>
  );
}

ReceiveInfoReader.defaultProps = {
  placeholder: 'Leia o lacre ou etiqueta'
};

ReceiveInfoReader.propTypes = {
  onSuccessCallback: PropTypes.func.isRequired,
  onErrorCallback: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  placeholder: PropTypes.string
};

export const MESSAGES = {
  RECEIVE_ERROR: 'Erro ao receber',
  LIMITED_RECEIVE_FOR_LEVE:
    'Eita, esse pacote não é de coleta ou transferência. Faça o recebimento pelo Arco.',
  RECEIVE_TRANSFER_ERROR:
    'Esta base ainda não está habilitada a receber transferências por aqui.'
};

function ReceiveInitialIdentification({
  goBack,
  handleReceiveInfo,
  handleError,
  errorMessage,
  setErrorMessage
}) {
  return (
    <Box>
      <HeaderWithReturn onReturn={goBack} title="Receber" />
      <Container maxWidth="xs">
        <Box
          pt={10}
          display="flex"
          flexDirection="column"
          overflow="hidden"
          height="100vh"
        >
          <ReceiveInfoReader
            onSuccessCallback={handleReceiveInfo}
            onErrorCallback={handleError}
            onChange={() => setErrorMessage('')}
            placeholder="Bipe o código da unidade"
          />
          <Box
            display="flex"
            flexDirection="column"
            alignItems="center"
            justifyContent="center"
            height={1}
          >
            <EmptySearch />
            <Typography variant="subtitle2" align="center">
              <Box fontWeight="fontWeightMedium" mt={3}>
                Bipe o código da unidade para <br /> receber na base.
              </Box>
            </Typography>
            {errorMessage && (
              <Box>
                <Alert severity="error">{`${
                  MESSAGES.RECEIVE_ERROR
                }: ${errorMessage}`}</Alert>
              </Box>
            )}
          </Box>
        </Box>
      </Container>
    </Box>
  );
}

ReceiveInitialIdentification.propTypes = {
  goBack: PropTypes.func.isRequired,
  handleReceiveInfo: PropTypes.func.isRequired,
  handleError: PropTypes.func.isRequired,
  errorMessage: PropTypes.string.isRequired,
  setErrorMessage: PropTypes.func.isRequired
};

const unauthorizedOriginForLeves = origin =>
  origin !== PACKAGE_ORIGIN.PACKAGE_ORIGIN_PICKUP &&
  origin !== PACKAGE_ORIGIN.PACKAGE_ORIGIN_UNKNOWN &&
  origin !== PACKAGE_ORIGIN.PACKAGE_ORIGIN_FLECHA &&
  origin !== PACKAGE_ORIGIN.PACKAGE_ORIGIN_TRANSFER &&
  origin !== PACKAGE_ORIGIN.PACKAGE_ORIGIN_PARTNER;

const isUnkownOrigin = (isUserLeve, origin) =>
  isUserLeve() && unauthorizedOriginForLeves(origin);

/**
 * This screen is responsible for the first Beep in the
 * receive process. It will fetch receive info and then decide
 * which workflow is going to be executed.
 */
export default function InitialIdentification() {
  const history = useHistory();
  const [errorMessage, setErrorMessage] = useState('');
  const {
    sortingContext,
    setPackToReceive,
    setSender,
    setShouldShowSender,
    setSenderPackages,
    setPackagesHaveItinerary,
    setPackagesOrigin,
    setLastPackageOrigin,
    setPackageBarcode,
    setIncomingUnitLoads,
    setUnitLoadToReceive,
    setSenderList,
    senderList
  } = useContext(ReceivingProcessContext);
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    // prevent user for opening this page when editing the URL
    if (history.action !== HISTORY_ACTIONS.PUSH) {
      history.replace(ROUTES.RECEIVE.CONTEXT_READER);
    }
  }, [history]);

  const {
    state: { selectedDistributionCenter }
  } = useDistributionCenter();
  const { trackStart, trackEnd } = useContext(ActivityTrackingContext);

  const enableReceiveBagUnknownOrigin = useFeature(
    SWITCHES.enableReceiveBagUnknownOrigin
  );

  const enableNewReceiveFlow = useFeature(SWITCHES.enableNewReceiveFlow);

  const enablePackageOriginPartnerFlow = useFeature(
    SWITCHES.enablePackageOriginPartnerFlow
  );

  const isUserLeve = () => {
    return (
      getUserType(selectedDistributionCenter) ===
      COGNITO_DISTRIBUTION_CENTER.LEVE_USER
    );
  };

  const goTo = pathname => {
    trackEnd(
      OPERATIONAL_PROCESS.BEEP_LATENCY,
      ACTIVITY.INITIAL_IDENTIFICATION_BEEP
    );
    history.push({ pathname });
  };

  const handleError = message => {
    setErrorMessage(message);
    playErrorBeep();
    trackEnd(
      OPERATIONAL_PROCESS.BEEP_LATENCY,
      ACTIVITY.INITIAL_IDENTIFICATION_BEEP
    );
  };

  const receiveBagUnknownOrigin = async barcode => {
    trackStart(OPERATIONAL_PROCESS.RECEIVE, ACTIVITY.BAG_UNKNOWN_ORIGIN);

    const defaultReceivingUnitLoad = `${
      selectedDistributionCenter.routingCode
    } ${UNIFIED_RECEIVE_UNIT_LOAD_SUFFIX}`;

    const response = await movePackage(
      defaultReceivingUnitLoad,
      barcode,
      defaultReceivingUnitLoad,
      sortingContext,
      {
        distributionCenterId: selectedDistributionCenter?.distributionCenterId
      }
    ).catch(err => handleRestAPIError(err, errorMsg => handleError(errorMsg)));

    if (!response) return;

    showSnackbar({
      variant: 'success',
      message: 'Boa! Unidade de origem desconhecida foi recebida.',
      showCloseButton: true,
      enqueueSnackbar,
      onEnter: () => playSuccessBeep()
    });

    trackEnd(OPERATIONAL_PROCESS.RECEIVE, ACTIVITY.BAG_UNKNOWN_ORIGIN);
  };

  const handleReceiveInfo = async ({
    responsePackage,
    senderInfo,
    incomingPackages,
    unitLoadToReceive,
    incomingUnitLoads,
    origin,
    barcode
  }) => {
    // Bags from unknown origin should just be moved.
    if (
      enableReceiveBagUnknownOrigin &&
      barcode &&
      origin === PACKAGE_ORIGIN.PACKAGE_ORIGIN_UNKNOWN &&
      !incomingUnitLoads.length &&
      !incomingPackages.length
    ) {
      await receiveBagUnknownOrigin(barcode);

      trackEnd(
        OPERATIONAL_PROCESS.BEEP_LATENCY,
        ACTIVITY.INITIAL_IDENTIFICATION_BEEP
      );
      return;
    }

    if (isUnkownOrigin(isUserLeve, origin)) {
      setErrorMessage(MESSAGES.LIMITED_RECEIVE_FOR_LEVE);
      trackEnd(
        OPERATIONAL_PROCESS.BEEP_LATENCY,
        ACTIVITY.INITIAL_IDENTIFICATION_BEEP
      );
      return;
    }

    setSender(senderInfo);

    setPackToReceive(responsePackage);
    setPackageBarcode(responsePackage?.barcode);
    setSenderPackages(incomingPackages);

    setUnitLoadToReceive(unitLoadToReceive);
    setIncomingUnitLoads(incomingUnitLoads);

    setPackagesOrigin(origin);
    setLastPackageOrigin(origin);

    switch (origin) {
      case PACKAGE_ORIGIN.PACKAGE_ORIGIN_PICKUP:
      case PACKAGE_ORIGIN.PACKAGE_ORIGIN_FLECHA:
        // This state can be removed after all workflows are split
        setPackagesHaveItinerary(true);
        goTo('/receive');
        break;
      case PACKAGE_ORIGIN.PACKAGE_ORIGIN_NOT_INITIATED:
        goTo('/receive');
        break;
      case PACKAGE_ORIGIN.PACKAGE_ORIGIN_PARTNER:
        if (enablePackageOriginPartnerFlow) {
          setShouldShowSender(true);
          setSenderList(senderList + 1);
        }
        goTo('/receive/counter');
        break;
      case PACKAGE_ORIGIN.PACKAGE_ORIGIN_UNKNOWN:
        goTo('/receive/counter');
        break;
      case PACKAGE_ORIGIN.PACKAGE_ORIGIN_REDISPATCH:
        goTo('/receive/redispatch');
        break;
      case PACKAGE_ORIGIN.PACKAGE_ORIGIN_TRANSFER:
        goTo('/receive/transfers');
        break;
      default:
        goTo('/receive');
        break;
    }
  };

  const goBack = () => {
    if (selectedDistributionCenter) {
      history.push({
        pathname: '/'
      });
      return;
    }

    history.push({
      pathname: '/receive/context-reader'
    });
  };

  useEffect(() => {
    trackStart(OPERATIONAL_PROCESS.RECEIVE, ACTIVITY.FULL_PROCESS);
    trackStart(OPERATIONAL_PROCESS.RECEIVE, ACTIVITY.INITIAL_IDENTIFICATION);
    trackStart(OPERATIONAL_PROCESS.BEEP_LATENCY, ACTIVITY.FULL_PROCESS);

    return () => {
      trackEnd(OPERATIONAL_PROCESS.RECEIVE, ACTIVITY.INITIAL_IDENTIFICATION);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return enableNewReceiveFlow ? (
    <ReceiveInitialIdentification
      goBack={goBack}
      handleReceiveInfo={handleReceiveInfo}
      handleError={handleError}
      errorMessage={errorMessage}
      setErrorMessage={setErrorMessage}
    />
  ) : (
    <Box>
      <Container maxWidth="xs">
        <Box
          pt={3}
          display="flex"
          flexDirection="column"
          height={1}
          overflow="hidden"
        >
          <Box>
            <HeaderWithCancel
              testId="receive-cancel-button"
              handleClose={goBack}
            />
            <Box paddingTop={1.5}>
              <Typography component="div" variant="body1" gutterBottom>
                <Box fontWeight="fontWeightBold">Receber</Box>
              </Typography>
            </Box>
          </Box>
          <Box display="flex" flexDirection="column" height={1}>
            <Box>
              <Typography variant="h5">
                Bipe o lacre ou etiqueta e comece a receber
              </Typography>
            </Box>
            <ReceiveInfoReader
              onSuccessCallback={handleReceiveInfo}
              onErrorCallback={handleError}
              onChange={() => setErrorMessage('')}
            />
            {errorMessage && (
              <Box>
                <Alert severity="error">{`${
                  MESSAGES.RECEIVE_ERROR
                }: ${errorMessage}`}</Alert>
              </Box>
            )}
          </Box>
        </Box>
      </Container>
    </Box>
  );
}
