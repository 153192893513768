import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import 'moment/locale/pt-br';
import {
  Avatar,
  Box,
  Button,
  Container,
  GridList,
  IconButton,
  Typography
} from '@material-ui/core';
import { colors } from '@loggi/mar';
import { ReceivingProcessContext } from '../receiving-process-context';
// eslint-disable-next-line import/no-cycle
import { ReceivedPackagesList, useStyles } from '../receive';
// eslint-disable-next-line import/no-cycle
import { ReactComponent as ShortArrowLeft } from '../../../assets/images/short-arrow-left.svg';
import { BUTTON_LABEL_TEXT } from '../../../constants';

moment.updateLocale('pt-BR');

export default function ReceivePartner({ children, goBack, completeReceive }) {
  return (
    <Box height="100vh" overflow="hidden">
      <Container maxWidth="xs">
        <PartnerHeader goBack={goBack} />
        {children}
        <PartnerPackages completeReceive={completeReceive} />
      </Container>
    </Box>
  );
}

ReceivePartner.propTypes = {
  // This allows single or multiple React elements, strings, numbers, etc.
  children: PropTypes.node.isRequired,
  goBack: PropTypes.func.isRequired,
  completeReceive: PropTypes.func.isRequired
};

export function PartnerPackages({ completeReceive }) {
  return (
    <Box width="100%" mt={1}>
      <Box
        display="flex"
        flexDirection="column"
        style={{
          position: 'relative',
          overflowY: 'auto',
          maxHeight: `calc(55vh - 100px)`
        }}
        flex="1 1 1"
      >
        <ReceivedPackagesList />
      </Box>
      <Container
        maxWidth="xs"
        style={{
          position: 'fixed',
          bottom: 0,
          left: 0,
          right: 0
        }}
      >
        <Box flex="none" mb={3}>
          <Button
            variant="contained"
            fullWidth
            color="primary"
            size="large"
            onClick={completeReceive}
            data-testid="receive-partner-button-confirm"
          >
            {BUTTON_LABEL_TEXT.CONFIRM}
          </Button>
        </Box>
      </Container>
    </Box>
  );
}

PartnerPackages.propTypes = {
  completeReceive: PropTypes.func.isRequired
};

export function PartnerHeader({ goBack }) {
  const classes = useStyles();
  const { senderPackages, returnList } = useContext(ReceivingProcessContext);

  return (
    <Box className={classes.flexBox} overflow="hidden">
      <Box>
        <Box>
          <Box className={classes.grid}>
            <Box
              display="flex"
              flexDirection="row"
              alignItems="center"
              mb={2}
              justifyContent="space-between"
            >
              <Box display="flex" alignItems="center">
                <IconButton
                  onClick={goBack}
                  edge="start"
                  data-testid="icon-partner-header-return"
                  disabled={false}
                >
                  <ShortArrowLeft fill={colors.blue[500]} />
                </IconButton>
                <Box data-testid="header-with-return-title">
                  <Typography component="span" variant="body2">
                    <Box fontWeight="fontWeightBold">
                      {''}
                      {''} Receber
                    </Box>
                  </Typography>
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
      <Box
        overflow="hidden"
        flex={1}
        data-test-id="box-sender-counter-packages"
      >
        <Box className={classes.flexBox}>
          <Box>
            <Box>
              <Typography
                variant="h3"
                style={{ color: colors.blue[500] }}
                display="inline"
                data-testid="receive-package-count"
              >
                {returnList.length}{' '}
              </Typography>
              <Typography
                variant="h3"
                display="inline"
                data-testid="receive-package-total"
              >
                de {senderPackages.length}
              </Typography>
              <Typography>pacotes</Typography>
            </Box>
            <DriverPartnerInfo />
          </Box>
        </Box>
      </Box>
    </Box>
  );
}

PartnerHeader.propTypes = {
  goBack: PropTypes.func.isRequired
};

export function DriverPartnerInfo() {
  const classes = useStyles();
  const { sender } = useContext(ReceivingProcessContext);
  return (
    <GridList cellHeight="auto" cols={5} spacing={1}>
      <Box cols={3.5} className={classes.flexName}>
        <Typography variant="body1">
          <Box fontWeight="fontWeightBold" data-testid="sender-name">
            {sender?.name}
          </Box>
        </Typography>
        {sender.cpf && (
          <Typography variant="caption" data-testid="sender-cpf">
            CPF: {sender?.cpf}
          </Typography>
        )}
      </Box>
      <Box cols={1.5} className={classes.flexAvatar}>
        {sender.photoUrl && (
          <Avatar
            src={sender.photoUrl}
            className={classes.smallAvatar}
            data-testid="sender-avatar"
          />
        )}
      </Box>
    </GridList>
  );
}
