import {
  Box,
  Button,
  Container,
  Divider,
  List,
  ListItem,
  Typography
} from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { useSnackbar } from 'notistack';
import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import { identifyPackagesToReceive, movePackage } from '../../api-rest';
import { PackageReader } from '../../app/components/barcode-readers';
import HeaderWithCancel from '../../app/components/header-with-cancel';
import { PACKAGE_ORIGIN } from '../../app/enums';
import pluralize from '../../app/utils/pluralize';
import handleRestAPIError from '../../app/utils/rest-api-request';
import {
  ACTIVITY,
  HISTORY_ACTIONS,
  OPERATIONAL_PROCESS,
  RECEIVE_TRANSFER_PAGE_STATE,
  BUTTON_LABEL_TEXT,
  UNIFIED_RECEIVE_UNIT_LOAD_SUFFIX
} from '../../constants';
import showSnackbar from '../../app/components/snackbar/snackbar-container';
import { playErrorBeep, playSuccessBeep } from '../../sounds';
import { ReceivingProcessContext } from './receiving-process-context';
import { useDistributionCenter } from '../../app/access-control/distribution-center-provider';
import { ActivityTrackingContext } from '../../app/activity-tracking/activity-tracking-provider';
import SortingRecommendation from './sorting-recommendation';

const ReceiveTransfers = () => {
  const { pageState } = useContext(ReceivingProcessContext);

  return (
    <>
      {pageState === RECEIVE_TRANSFER_PAGE_STATE.BEEP_UNITLOADS && (
        <UnitLoadBeepScreen />
      )}
    </>
  );
};

export const MESSAGES = {
  UNIT_FROM_ANOTHER_TRANSFER: 'Opa, essa unidade é de outra transferência.',
  DUPLICATED_BEEP: 'Opa, unidade já bipada!',
  NOT_IN_TRANSFER: 'Opa, você recebeu uma unidade que não é de transferência.'
};

const UnitLoadBeepScreen = () => {
  const {
    sender,
    sortingContext,
    incomingUnitLoads,
    receivedUnitLoads,
    setReceivedUnitLoads,
    unitLoadToReceive,
    setUnitLoadToReceive,
    packToReceive,
    setPackToReceive
  } = useContext(ReceivingProcessContext);

  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [currentBarcode, setCurrentBarcode] = useState('');
  const [unexpectedUnitLoadsCount, setUnexpectedUnitLoadsCount] = useState(0);
  const {
    state: { selectedDistributionCenter }
  } = useDistributionCenter();
  const { trackStart, trackEnd } = useContext(ActivityTrackingContext);

  const defaultReceivingUnitLoad = `${
    selectedDistributionCenter.routingCode
  } ${UNIFIED_RECEIVE_UNIT_LOAD_SUFFIX}`;

  const transfer = {
    id: sender?.transfer?.id,
    origin: sender?.transfer?.distributionCenter?.routingCode,
    destination: sender?.transfer?.expeditionCenter?.routingCode,
    numBags: sender?.transfer?.numBags
  };

  const warningAlert = async message => {
    showSnackbar({
      variant: 'warning',
      message,
      showCloseButton: true,
      enqueueSnackbar
    });
  };

  const isDuplicatedUnitLoadBeep = licensePlate =>
    receivedUnitLoads.filter(item => item.licensePlate === licensePlate)[0];

  const shouldSortUnitLoad = currentUnitLoad =>
    currentUnitLoad &&
    incomingUnitLoads.filter(
      unitLoad => unitLoad.licensePlate === currentUnitLoad.licensePlate
    )[0];

  const baseErrorHandler = message => {
    setErrorMessage(message);
    playErrorBeep();
    setLoading(false);
  };

  const updateReceivedUnitLoads = (isUnexpectedUnitLoad, receivedUnitLoad) => {
    if (isUnexpectedUnitLoad) {
      setUnexpectedUnitLoadsCount(unexpectedUnitLoadsCount + 1);
    }

    setReceivedUnitLoads([receivedUnitLoad, ...receivedUnitLoads]);
  };

  const movePackageForDefaultUL = barcode => {
    return movePackage(
      defaultReceivingUnitLoad,
      barcode,
      defaultReceivingUnitLoad,
      sortingContext,
      {
        distributionCenterId: selectedDistributionCenter?.distributionCenterId
      }
    );
  };

  const sortIncomingUnitLoad = async ({
    barcode,
    destinationRoutingCode = '',
    isUnexpectedUnitLoad
  }) => {
    setLoading(true);
    try {
      await movePackageForDefaultUL(barcode);
      updateReceivedUnitLoads(isUnexpectedUnitLoad, {
        licensePlate: barcode,
        destinationLicensePlate: defaultReceivingUnitLoad,
        distributionCenterDestination: destinationRoutingCode
      });
      setLoading(false);
      playSuccessBeep();
    } catch (err) {
      handleRestAPIError(err, baseErrorHandler);
    }
  };

  const shouldReceiveUnitLoad = async currentUnitLoad => {
    if (isDuplicatedUnitLoadBeep(currentUnitLoad.licensePlate)) {
      warningAlert(MESSAGES.DUPLICATED_BEEP);
    } else if (shouldSortUnitLoad(currentUnitLoad)) {
      await sortIncomingUnitLoad({
        barcode: currentUnitLoad.licensePlate,
        destinationRoutingCode: currentUnitLoad
          ? currentUnitLoad.destination.routing_code
          : ''
      });
    }
  };

  const unexpectedReceive = async licensePlate => {
    if (isDuplicatedUnitLoadBeep(licensePlate)) {
      warningAlert(MESSAGES.DUPLICATED_BEEP);
      return;
    }

    setLoading(true);
    const response = await identifyPackagesToReceive(licensePlate).catch(err =>
      handleRestAPIError(err, baseErrorHandler)
    );

    if (!response?.data) {
      return;
    }

    const {
      origin: unexpectedOrigin,
      remainingUnitLoads: unexpectedRemainingUnitLoads,
      senderInfo: unexpectedSender
    } = response.data;

    setLoading(false);

    const receivedUnitLoad = unexpectedRemainingUnitLoads?.filter(
      unitLoad => unitLoad.licensePlate === licensePlate
    )[0];

    if (unexpectedOrigin === PACKAGE_ORIGIN.PACKAGE_ORIGIN_TRANSFER) {
      if (unexpectedSender?.transfer?.id !== transfer.id) {
        warningAlert(MESSAGES.UNIT_FROM_ANOTHER_TRANSFER);
      }
    } else {
      warningAlert(MESSAGES.NOT_IN_TRANSFER);
    }

    await sortIncomingUnitLoad({
      barcode: licensePlate,
      destinationRoutingCode: receivedUnitLoad
        ? receivedUnitLoad.destination.routing_code
        : '',
      isUnexpectedUnitLoad: true
    });
  };

  const handleRead = async licensePlate => {
    setErrorMessage('');
    setCurrentBarcode('');
    setCurrentBarcode(licensePlate);
  };

  /**
   * We are handling the barcode reading by using
   * an useEffect to avoid stale-closure caused by
   * our camera callback for barcode reading
   */
  useEffect(() => {
    if (!currentBarcode) return;

    trackStart(
      OPERATIONAL_PROCESS.BEEP_LATENCY,
      ACTIVITY.RECEIVE_TRANSFER_BEEP
    );

    const handleCurrentBarcode = async () => {
      const currentUnitLoad = incomingUnitLoads.filter(
        unitLoad => unitLoad.licensePlate === currentBarcode
      )[0];

      if (currentUnitLoad) {
        await shouldReceiveUnitLoad(currentUnitLoad);
      } else {
        await unexpectedReceive(currentBarcode);
      }
      setCurrentBarcode('');
      trackEnd(
        OPERATIONAL_PROCESS.BEEP_LATENCY,
        ACTIVITY.RECEIVE_TRANSFER_BEEP
      );
      trackEnd(OPERATIONAL_PROCESS.RECEIVE, ACTIVITY.BEEP_UNIT_LOAD);
      trackStart(OPERATIONAL_PROCESS.RECEIVE, ACTIVITY.BEEP_UNIT_LOAD);
    };

    handleCurrentBarcode();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentBarcode]);

  const finish = () => {
    history.push({
      pathname: '/receive/context-reader'
    });
  };

  const receiveSuccess = async () => {
    showSnackbar({
      variant: 'success',
      message: 'Pronto, tudo certo.\nUnidades recebidas.',
      showCloseButton: true,
      enqueueSnackbar
    });
    playSuccessBeep();
    trackEnd(OPERATIONAL_PROCESS.RECEIVE, ACTIVITY.BEEP_UNIT_LOAD);
    trackEnd(OPERATIONAL_PROCESS.RECEIVE, ACTIVITY.RECEIVE_TRANSFER);
    trackEnd(OPERATIONAL_PROCESS.RECEIVE, ACTIVITY.FULL_PROCESS);
    trackEnd(OPERATIONAL_PROCESS.BEEP_LATENCY, ACTIVITY.FULL_PROCESS);
    finish();
  };

  useEffect(() => {
    trackStart(OPERATIONAL_PROCESS.RECEIVE, ACTIVITY.RECEIVE_TRANSFER);
    trackStart(OPERATIONAL_PROCESS.RECEIVE, ACTIVITY.BEEP_UNIT_LOAD);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    // prevent user for opening this page when editing the URL
    if (history.action !== HISTORY_ACTIONS.PUSH || !sortingContext) {
      history.replace('/receive/context-reader');
    }
  }, [history, sortingContext]);

  /**
   * This initial sort should be executed only once
   */
  useEffect(() => {
    const handleFirstUnitLoad = async () => {
      await sortIncomingUnitLoad({
        barcode: unitLoadToReceive.licensePlate,
        destinationRoutingCode: unitLoadToReceive
          ? unitLoadToReceive.destination.routing_code
          : ''
      });
      setUnitLoadToReceive(null);
    };

    const handleFirstPackage = async () => {
      await sortIncomingUnitLoad({
        barcode: packToReceive.barcode,
        isUnexpectedUnitLoad: true
      });
      setPackToReceive(null);
    };

    if (unitLoadToReceive) {
      handleFirstUnitLoad();
      return;
    }

    if (packToReceive) handleFirstPackage();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Box height="100vh" clone>
      <Container maxWidth="xs">
        <Box
          pt={3}
          display="flex"
          flexDirection="column"
          height={1}
          overflow="hidden"
        >
          <Box>
            <HeaderWithCancel
              testId="receive-transfer-cancel-button"
              handleClose={finish}
            />
            <Box paddingTop={1.5}>
              <Typography component="div" variant="body1" gutterBottom>
                <Box fontWeight="fontWeightBold">Receber</Box>
              </Typography>
            </Box>

            <Box display="flex">
              <Typography
                color="secondary"
                variant="h3"
                data-testid="checked-unitloads-count"
              >
                {receivedUnitLoads.length - unexpectedUnitLoadsCount}
              </Typography>
              <Typography variant="h3">
                &nbsp;de {incomingUnitLoads.length}
              </Typography>
            </Box>

            <Typography variant="body1">
              {`${pluralize({
                singular: 'unidade',
                count: incomingUnitLoads.length
              })}`}
            </Typography>

            <Box display="flex" flexDirection="row" alignItems="center">
              <Box mt={3}>
                <Typography component={Box} variant="body1">
                  <Box fontWeight="fontWeightBold">{`${transfer.origin} > ${
                    transfer.destination
                  }`}</Box>
                  <Box fontWeight="fontWeightRegular">{`#${
                    transfer.id
                  } - veio deixar ${transfer.numBags}`}</Box>
                </Typography>
              </Box>
            </Box>

            <Box className="centered" mt={2.5}>
              <PackageReader
                onRead={handleRead}
                loading={loading}
                notes="Bipe na tela de recebimento de transferência"
                placeholder="Bipe o lacre ou etiqueta"
              />
            </Box>
            {errorMessage && (
              <Box mt={1.5}>
                <Alert severity="error">{errorMessage}</Alert>
              </Box>
            )}
          </Box>

          <Box my={2} flex={1} style={{ overflowY: 'auto' }}>
            <UnitLoadList receivedUnitLoads={receivedUnitLoads} />
          </Box>
          <Box mb={1.5} flex="initial">
            <Button
              fullWidth
              variant="contained"
              color="primary"
              size="large"
              data-testid="receive-transfer-button"
              onClick={receiveSuccess}
            >
              {BUTTON_LABEL_TEXT.CONFIRM}
            </Button>
          </Box>
        </Box>
      </Container>
    </Box>
  );
};

const UnitLoadList = ({ receivedUnitLoads }) => {
  return (
    <List data-testid="package-list">
      {receivedUnitLoads.map((item, i) => (
        <UnitLoadItem
          key={item.licensePlate}
          unitLoadItem={item}
          bottomDivider={receivedUnitLoads.length - 1 === i}
        />
      ))}
    </List>
  );
};

UnitLoadList.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  receivedUnitLoads: PropTypes.array.isRequired
};

const UnitLoadItem = ({ unitLoadItem, bottomDivider }) => {
  const { sortingContext } = useContext(ReceivingProcessContext);

  return (
    <Box
      mb={bottomDivider ? 0 : 1}
      data-testid={`unitload-item-${unitLoadItem.licensePlate}`}
    >
      <Divider />
      <Box mt={1} mb={bottomDivider ? 1 : 0} paddingLeft={0} clone>
        <ListItem>
          <Box>
            <Typography display="block" variant="body2" gutterBottom>
              #{unitLoadItem.licensePlate}
            </Typography>
            <Typography component="div" variant="body2" gutterBottom>
              {unitLoadItem.distributionCenterDestination}
            </Typography>
            <SortingRecommendation
              sortingContext={sortingContext}
              barcode={unitLoadItem.licensePlate}
            />
          </Box>
        </ListItem>
      </Box>
      {bottomDivider && <Divider />}
    </Box>
  );
};

UnitLoadItem.defaultProps = {
  bottomDivider: false
};

UnitLoadItem.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  unitLoadItem: PropTypes.object.isRequired,
  bottomDivider: PropTypes.bool
};

export default ReceiveTransfers;
