import React, { useContext, useEffect } from 'react';
import 'moment/locale/pt-br';

import {
  Box,
  Button,
  GridList,
  Typography,
  GridListTile
} from '@material-ui/core';
import PropTypes from 'prop-types';

import pluralize from '../../app/utils/pluralize';
import { ACTIVITY, OPERATIONAL_PROCESS } from '../../constants';
import { ReceivingProcessContext } from './receiving-process-context';
import { ActivityTrackingContext } from '../../app/activity-tracking/activity-tracking-provider';

export default function IncompleteReceiveScreenBody({ receiveSuccess }) {
  const {
    senderPackages,
    receivedSenderPackages,
    setIncompleteReceive
  } = useContext(ReceivingProcessContext);
  const { trackStart, trackEnd } = useContext(ActivityTrackingContext);

  const pendingPacks = senderPackages.length - receivedSenderPackages;

  useEffect(() => {
    trackStart(OPERATIONAL_PROCESS.RECEIVE, ACTIVITY.INCOMPLETE_RECEIVE);

    return () => {
      trackEnd(OPERATIONAL_PROCESS.RECEIVE, ACTIVITY.INCOMPLETE_RECEIVE);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Box
      data-testid="incomplete-receive-body-component"
      display="flex"
      flexDirection="column"
      justifyContent="space-between"
      height="100%"
    >
      <Box pt={2.5}>
        <Typography variant="h5">
          Opa, ainda tem pacotes com esse entregador.
        </Typography>
        <Typography variant="h3">{pendingPacks}</Typography>
        <Typography variant="body1">
          {`${pluralize({
            singular: 'pacote',
            count: pendingPacks
          })}`}
        </Typography>
      </Box>
      <Box pb={3}>
        <Box mb={3}>
          <Typography variant="body1" mb={3}>
            Se você não recebe todos os pacotes, o entregador fica com a rota em
            aberto.
          </Typography>
        </Box>
        <GridList cellHeight="auto" cols={2}>
          <GridListTile cols={1}>
            <Box paddingTop="20px">
              <Button
                variant="outlined"
                color="primary"
                size="large"
                fullWidth
                onClick={async () => {
                  setIncompleteReceive(false);
                }}
              >
                Voltar
              </Button>
            </Box>
          </GridListTile>
          <GridListTile cols={1}>
            <Box paddingTop="20px">
              <Button
                variant="contained"
                color="primary"
                size="large"
                fullWidth
                data-testid="incomplete-receive-confirm-button"
                onClick={receiveSuccess}
              >
                Continuar
              </Button>
            </Box>
          </GridListTile>
        </GridList>
      </Box>
    </Box>
  );
}

IncompleteReceiveScreenBody.propTypes = {
  receiveSuccess: PropTypes.func.isRequired
};
