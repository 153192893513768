import { colors } from '@material-ui/core';
import { PACKAGE_STATUS_CODE } from './app/enums';

export const NETWORK_ERROR_MESSAGE = 'Confira a sua conexão e tente outra vez.';
export const UNKNOWN_ERROR = 'Ocorreu um erro desconhecido.';

export const APP_NAME = 'xd_app';

export const BROWSER_NETWORK_ERROR_MESSAGE = {
  chrome: 'Failed to fetch',
  firefox: 'NetworkError when attempting to fetch resource.',
  safari: 'Network request failed'
};

export const TASK_ACK_STATUS_CHOICES = {
  IMPAIRED_DELIVERY: 'Entrega prejudicada'
};

export const CANCEL_PICKUP_REASON = {
  INVALID: 0,
  NO_PACKAGES_TO_PICKUP: 1,
  COULDNT_PICKUP: 2,
  NO_ONE_IN_PICKUP: 3,
  DIDNT_FIND_THE_ADDRESS: 4,
  BY_OPERATOR: 5
};

export const ERRORS = {
  SERVER_FAILED_TO_RESPOND: 'Não foi possível completar a requisição',
  SOMETHING_WRONG_TRY_AGAIN: 'Eita, algo deu errado. Tente outra vez.',
  PACKAGE_NOT_FOUND: 'Pacote não encontrado',
  UNEXPECTED_PACKAGE:
    'Eita, esse pacote não pertence a essa saca. Bipe um local para armazená-lo',
  USER_NOT_ALLOWED: 'Usuario nao permitido',
  DEACTIVATED_USER: 'User is deactivated'
};

export const BAG_PACKAGE_ERROR_TYPE = {
  BAG_PACKAGE_ERROR_TYPE_INVALID:
    'Eita, problema com o pacote. Bipe um local para armazená-lo.',
  BAG_PACKAGE_ERROR_TYPE_NOT_REDISPATCH:
    'Eita, essa etiqueta não é de redespacho. Bipe um local para armazená-lo.',
  BAG_PACKAGE_ERROR_TYPE_REDISPATCH_WRONG_AGENCY:
    'Eita, esse pacote deve ser coletado por outra AGF. Bipe um local para armazená-lo.',
  BAG_PACKAGE_ERROR_TYPE_REDISPATCH_EXPIRED_LABEL:
    'Eita, essa etiqueta está expirada. Bipe um local para armazená-lo.',
  BAG_PACKAGE_ERROR_TYPE_REDISPATCH_WRONG_ADDRESS:
    'Eita, o endereço desse pacote foi alterado. Bipe um local para armazená-lo.',
  BAG_PACKAGE_ERROR_TYPE_REDISPATCH_STATUS_CANCELED_DELIVERY:
    'Eita, este pacote foi cancelado pelo embarcador. Bipe um local para armazená-lo.'
};

export const REDISPATCH_LABEL_ERROR_TYPE = {
  REDISPATCH_LABEL_ERROR_TYPE_INVALID: 'Eita, problema com o pacote.',

  REDISPATCH_LABEL_ERROR_TYPE_SHIPPING_CANCELED:
    'Eita, esse pacote foi cancelado pelo embarcador. ' +
    'Armazene para devolver.',

  REDISPATCH_LABEL_ERROR_TYPE_PACKAGE_LOST:
    'Eita, esse pacote foi extraviado. Armazene para devolver.',

  REDISPATCH_LABEL_ERROR_TYPE_FORBIDDEN_REDISPATCH:
    'Eita, esse embarcador não aceita entregar com Correios. ' +
    'Armazene para devolver.',

  REDISPATCH_LABEL_ERROR_TYPE_INVALID_DIMENSIONS:
    'Eita, pacote com dimensões ou peso acima do permitido.',

  REDISPATCH_LABEL_ERROR_TYPE_INVALID_ZIP_CODE:
    'Eita, pacote para um cep inválido.',

  REDISPATCH_LABEL_ERROR_TYPE_INVALID_CITY_STATE:
    'Eita, pacote para uma cidade ou estado inválido.',

  REDISPATCH_LABEL_ERROR_TYPE_TRACKING_CODE_EXHAUSTED:
    'Eita, não existem códigos de rastreio disponíveis. Fale com o suporte.',

  REDISPATCH_LABEL_ERROR_TYPE_POSTING_CARD_NOT_FOUND:
    'Eita, erro no cartão de postagem. Fale com o suporte.',

  REDISPATCH_LABEL_ERROR_TYPE_BUILD_ERROR:
    'Eita, pacote com endereço inválido. Verifique se o endereço contém apenas letras e números.',

  REDISPATCH_LABEL_ERROR_TYPE_NETWORK_ERROR:
    'Eita, algo deu errado. Tente outra vez.',

  REDISPATCH_LABEL_ERROR_TYPE_UNKNOWN:
    'Eita, algo deu errado. Fale com o suporte.',

  REDISPATCH_LABEL_ERROR_TYPE_INVALID_INVOICE_NUMBER:
    'Eita, pacote sem nota fiscal. Correios não aceitam mais pacotes sem nota fiscal.'
};

export const UnitLoadExtraInfo = {
  UNIT_LOAD_EXTRA_INFO_INVALID: 'UNIT_LOAD_EXTRA_INFO_INVALID',
  UNIT_LOAD_EXTRA_INFO_IS_EMPTY: 'UNIT_LOAD_EXTRA_INFO_IS_EMPTY',
  UNIT_LOAD_EXTRA_INFO_IS_NOT_EMPTY: 'UNIT_LOAD_EXTRA_INFO_IS_NOT_EMPTY'
};

export const CARA_CRACHA_FOR_DRIVER = {
  CREATED: 'STATUS_CARA_CRACHA_FOR_DRIVER_CREATED',
  NOT_CREATED: 'STATUS_CARA_CRACHA_FOR_DRIVER_NOT_CREATED'
};

export const HISTORY_ACTIONS = {
  PUSH: 'PUSH',
  POP: 'POP'
};

export const RESPONSE_STATUS = {
  OK: 'RESPONSE_STATUS_OK',
  ERROR: 'RESPONSE_STATUS_ERROR'
};

export const EMOJI = {
  waving_hand: '👋'
};

export const ROUTES = {
  HOME: '/',
  NOT_FOUND: 'not-found',
  RECEIVE_INITIAL_IDENTIFICATION: '/receive/initial-identification',
  DISTRIBUTE_TRANSFERS_INITIAL_IDENTIFICATION: '/distribute/trip',
  DISTRIBUTE_TRIP_PROCESS: '/distribute/trip/process',
  DISTRIBUTE_PARTNER_CONTEXT_READER: '/distribute/partner-context-reader',
  DISTRIBUTE_PARTNER: '/distribute/license-plate-input',
  DISTRIBUTE_PARTNER_PROCESS: '/distribute/bag-package-reader',
  RECEIVE: {
    CONFIRMATION: '/receive/confirmation',
    CONTEXT_READER: '/receive/context-reader',
    MOVE_PACKAGES_BULK: '/receive/move-packages-bulk',
    RECEIVED_PACKAGES: '/receive/received-packages',
    COUNTER: '/receive/counter'
  },
  DISTRIBUTE: {
    ROOT: '/distribute',
    IDENTIFICATION: '/distribute/identification',
    INTENTION: '/distribute/intention',
    UNIT_READER: '/distribute/unit-reader',
    EVIDENCE: '/distribute/evidence',
    CHECKING: '/distribute/checking',
    SELECT_POSSIBLE_DESTINATIONS: '/distribute/destinations',
    ADD_CARGO: '/distribute/add-cargo',
    REVIEW_CARGO: '/distribute/review-cargo',
    NEW_DISTRIBUTION: '/distribute/new-distribution',
    EDIT_CARGO: '/distribute/edit-cargo',
    CANCEL_DISTRIBUTION: '/distribute/cancel'
  },
  PREPARE: {
    CHOOSE_LABEL_TYPE: '/prepare/choose-label-type'
  },
  ORGANIZE: {
    INITIAL_IDENTIFICATION: '/organize',
    SUMMARY: '/organize/summary',
    ORGANIZE_PACKAGE: '/organize/package',
    ORGANIZE_UNIT_LOAD: '/organize/unit-load'
  },
  DAMAGED_PACKAGE: {
    FORM: '/identify/declare-package-damage/v2/form',
    V2: '/identify/declare-package-damage/v2',
    MATCH_IMAGE: '/identify/declare-package-damage/v2/match-image',
    PREVISUALIZE_IMAGE: '/identify/declare-package-damage/v2/previsualize-image'
  },
  PACKING: '/packing'
};

export const DISTRIBUTE_TO = {
  TRANSFER: 'TRANSFER',
  FLECHA_ROUTE: 'FLECHA_ROUTE',
  LEVE_ROUTE: 'LEVE_ROUTE',
  REDISPATCH: 'REDISPATCH',
  RECEIVER: 'RECEIVER',
  DELIVERY: 'DELIVERY',
  PARTNER: 'PARTNER'
};

export const CUSTODY_EXCHANGE_CONTEXTS = {
  REVERSE: 'REV',
  DEVOLUTION: 'DEVO'
};

export const CUSTODY_EXCHANGE_ENABLED_CONTEXTS_LIST = [
  ...Object.values(CUSTODY_EXCHANGE_CONTEXTS)
];

export const CUSTODY_EXCHANGE_TYPE = {
  DELIVERY: 'DELIVERY',
  RETURN: 'RETURN',
  DEVOLUTION: 'DEVOLUTION',
  REVERSE: 'REVERSE'
};

export const RECENT_DISTRIBUTION_CENTERS = 'recentDistributionCenters';

export const LOGICAL_MISMATCH_OPERATIONAL_PROCESSES = {
  OPERATIONAL_PROCESS_DISTRIBUTE: 'OPERATIONAL_PROCESS_DISTRIBUTE'
};

export const SIGN_UP_GOOGLE_USER_LINK_REDIRECT =
  'PreSignUp+failed+with+error+User+account+has+been+linked+to+corresponding+Google+federated+account';

export const GSUITE_SIGN_UP_EMAIL_MUST_BE_FROM_LOGGI = {
  URL: 'PreSignUp+failed+with+error+The+email+domain+must+be+loggi.com',
  ERROR_MESSAGE:
    'Opa! Este e-mail não tem acesso à plataforma. Faça o login utilizando o e-mail loggi.com.'
};

export const GSUITE_SIGN_UP_CORPORATE_USER_MUST_EXIST = {
  URL:
    'PreSignUp+failed+with+error+Corporate+user+must+exist+for+automatic+user+migration',
  ERROR_MESSAGE: 'Ué, não encontramos uma conta com esse e-mail.'
};

export const DC_REDUCER_TYPE = {
  START_FETCH_OR_SET_DC: 'START_FETCH_OR_SET_DC',
  SUCCESS_FETCH_DC: 'SUCCESS_FETCH_DC',
  SUCCESS_SET_DC: 'SUCCESS_SET_DC',
  FAILURE_FETCH_DC: 'FAILURE_FETCH_DC'
};

export const COGNITO_DISTRIBUTION_CENTER = {
  LAST_MILE_COMPANY_TYPE_LOGGI: 'LAST_MILE_COMPANY_TYPE_LOGGI',
  LAST_MILE_COMPANY_TYPE_LEVE: 'LAST_MILE_COMPANY_TYPE_LEVE',
  LEVE_USER: 'LEVE',
  AGENCY_USER: 'AGENCY'
};

export const TRIP_PAGE_STATES = {
  READ_LICENSE_PLATE: 'READ_LICENSE_PLATE',
  BEEP_CARGO: 'BEEP_CARGO',
  REVIEW_CARGO_START_TANSFER: 'REVIEW_CARGO_START_TANSFER',
  EDIT_LIST_CARGO: 'EDIT_LIST_CARGO',
  END_SUCCESS: 'END_SUCCESS'
};

export const INTERNAL_SERVER_ERROR_MSG =
  'Eita! Tivemos um erro de sistema. Tente outra vez.';

export const BASE_TRIP_TRANSFER_ERROR_MSGS = {
  InvalidRoutingCode: 'Opa! O centro de distribuição de origem é inválido.',
  InvalidTripId: 'Opa! A viagem é inválida.',
  TripNotFound: 'Opa! Não foi encontrada nenhuma viagem.',
  InvalidLicensePlate: 'Opa! A placa do veículo é inválida.',
  InvalidDistributionUnitLoadId: 'Opa! A viagem é inválida.',
  DistributionUnitLoadAlreadyStarted: 'Opa! A distribuição já foi iniciada.'
};

export const GET_TRIPS_ERROR_MSGS = {
  InvalidLicensePlate: 'Opa! A placa do veículo é inválida. Tente outra vez.',
  TripNotFound:
    'Opa! Não foram encontradas viagens para placa do veículo indicada.',
  InvalidTripDate: 'Opa! A data da viagem é inválida.'
};

export const REMOVE_CARGO_ERROR_MSGS = {
  CargoNotFound: 'Opa! A carga não foi adicionada à transferência.',
  DuplicatedBarcode: 'Opa! Esse código de barras possui mais de um pacote.',
  InvalidLicensePlate:
    'Opa! A lista códigos de pacote ou lacres de saca é inválida.'
};

export const START_TRIP_TRANSFERS_ERROR_MSGS = {
  StartTransferProcessingError:
    'Início de transferência já em processamento, por favor, aguarde.',
  InvalidLicensePlate: 'Placa do veículo inválida.'
};

export const InvalidCargoDestination = 'InvalidCargoDestination';

export const DistributionUnitLoadAlreadyStarted =
  'DistributionUnitLoadAlreadyStarted';

export const ADD_CARGO_CONFIRMATION_MESSAGES = {
  CreateDuplicateTransfer:
    'Opa! Você está tentando adicionar uma carga em uma viagem já inicializada. Deseja forçar essa operação?',
  CargoInAnotherSavedTransfer:
    'Opa! Você está tentando mover uma carga que está em outra transferência. Deseja forçar esta operação?',
  PackageIsNotInSource:
    'Opa! Essa carga não está nesta base. Deseja forçar esta operação?',
  InvalidCargoDestination:
    'Opa! Você está tentando adicionar uma carga com destino inválido. Deseja forçar esta operação?'
};

export const ADD_CARGO_ERROR_MSGS = {
  InvalidTripId:
    'Opa! Nenhuma viagem foi informada. Essa informação é obrigatória.',
  TripNotFound:
    'Opa! Nenhuma viagem foi informada. Essa informação é obrigatória.',
  InvalidOrigin: 'Opa! Não existem viagens com origem válida.',
  InvalidCargo: 'Opa! O lacre da carga é inválido. Tente outra vez.',
  CargoAlreadyInTransfer:
    'Opa! Essa carga já foi adicionada ' +
    'a uma transferência em progresso ou finalizada.',
  DuplicatedBarcode: 'Opa! Esse código de barras possui mais de um pacote.',
  InvalidCargoDestination:
    'Opa! Não foi possível identificar o destino da carga.',
  PackageIsNotInSource:
    'Opa! Esse pacote não está neste centro de distribuição.',
  DestinationUnitLoadNotFound: 'Opa! Não existe transferência para esse pacote.'
};

export const SWITCHES = {
  enableOperationVerificationOnPackageReader:
    'enable_operation_verification_on_package_reader',
  enablePartnerDistributeContextReader:
    'enable_partner_distribute_context_reader',
  enablePackageOriginPartnerFlow: 'enable_package_origin_partner_flow',
  enablePartnerButtonInDistributeByEmail:
    'enable_partner_button_in_distribute_by_email',
  enablePartnerDistributeButtonByDC:
    'enable_partner_button_in_distribute_by_dc_id',
  enablePromisedDateOnSorting: 'enable_promised_date_on_sorting',
  enableDistributeTransfers: 'enable_distribute_transfers',
  skipCaraCrachaByDcId: 'skip_cara_cracha_by_dc_id',
  enableCaraCrachaInDistribute: 'enable_cara_cracha_in_distribute',
  enableDriverIdentificationInDistribute:
    'enable_driver_identification_in_distribute',
  enableLevePacking: 'enable_leve_packing',
  enableLeveTransport: 'enable_leve_transport',
  enableSortingRecommendation: 'enable_sorting_recommendation',
  enableReceiveBagUnknownOrigin: 'enable_receive_bag_unknown_origin',
  enableDisambiguateChangeLabel: 'enable_disambiguate_change_label',
  enablePrintDecon: 'enable_print_decon',
  showProcessDeadlineDate: 'show_process_deadline_date',
  trackingBeepsLatency: 'tracking_beeps_latency',
  showTransferCargoWeight: 'show_transfer_cargo_weight',
  showTransferCargoMerchandiseValue: 'show_transfer_cargo_merchandise_value',
  disableActivityTracking: 'disable_activity_tracking',
  disableConferenciaMeliMenu: 'disable_conferencia_meli_menu',
  disablePickupBulkOnReceive: 'disable_pickup_bulk_on_receive',
  disableTransportTransferMenu: 'disable_transport_transfer_menu',
  enableStartTransferUnitLoad: 'enable_start_transfer_unit_load',
  enableSortSurvey: 'enable_sort_survey',
  enableBulkScanDistributeTransfers: 'enable_bulk_scan_distribute_transfers',
  enableAddCargoWithInvalidDestination:
    'enable_add_cargo_with_invalid_destination',
  enableSelectSealOrPosition: 'enable_select_seal_or_position',
  enableDeclarePackageDamage: 'enable_declare_package_damage',
  enableDeclarePackageDamageByDc: 'enable_declare_package_damage_by_dc',
  enableDeclarePackageDamageV2ByDc: 'enable_declare_package_damage_v2_by_dc',
  enableSubmitDamagedPackagePhotosWithPresignedUrl:
    'enable_submit_damaged_package_photos_with_presigned_url',
  enableCreationDuplicatedTicketForDamagedPackage:
    'enable_creation_duplicated_ticket_for_damaged_package',
  enableRedispatchMenu: 'enable_redispatch_menu',
  enableShowPgrValues: 'enable_show_pgr_values',
  enableDistributionIndexesOnIdentify:
    'enable_distribution_indexes_on_identify',
  enableNewReceiveFlow: 'enable_new_receive_flow',
  enableNewReceiveFlowWithSibling: 'enable_new_receive_flow_with_sibling',
  enableConsolidationTypeOnIdentify: 'enable_consolidation_type_on_identify',
  enableDeclarePackageDamageV2AllDcsNoLeve:
    'enable_declare_package_damage_v2_all_dcs_no_leve',
  enableDeclarePackageDamageWithNewQuestion:
    'enable_declare_package_damage_with_new_question',
  enablePackingCreateBagEcRoutingCode:
    'enable_packing_create_bag_ec_routing_code',
  enableReturnInfoInsteadOfReturnAddress:
    'enable_return_info_instead_of_return_address',
  enableChangeBulkMoveToRedirectToDest:
    'enable_change_bulk_move_to_redirect_to_dest',
  enableChangeLicensePlateSentMovePackage:
    'enable_change_license_plate_sent_move_package',
  enableShowOutlierDetailsInOrganize: 'enable_show_outlier_details_in_organize',
  enableDisableFetchDecisions: 'enable_disable_fetch_decisions',
  enableDistributeTransferBasedOnNetwork:
    'enable_distribute_transfer_based_on_network',
  enablePrinterMenu: 'enable_printer_menu',
  disableDistributeTransfers: 'disable_distribute_transfers',
  disableDistributeTransferBasedOnNetwork:
    'disable_distribute_transfer_based_on_network',
  enableUpdateVehicleUnitLoad: 'enable_update_vehicle_unit_load',
  densityValue: 'density_value',
  enableDispatchFlow: 'enable_dispatch_flow',
  enableDispatchFlowValidFromDriver: 'enable_dispatch_flow_valid_from_driver',
  enableFilterByTrackingKey: 'enable_filter_by_tracking_key'
};

export const REMOTE_CONFIG_DEFAULT = {
  enable_get_distribute_info_rest: 'false',
  scandit_scanner_users: '[]',
  enable_move_package_rest: 'false',
  graphql_to_rest_migration_users: '[]',
  show_package_position_on_distribute: 'false',
  enable_redirect_by_operation_type: 'false',
  enable_infer_distribute_sorting_context: 'false',
  enable_selected_distribution_center_id_header: 'false',
  enable_unified_distribute: 'false',
  enable_skip_packages_beep_in_distribute: '[]',
  ...Object.assign(
    {},
    ...Object.values(SWITCHES).map(key => ({ [key]: 'false' }))
  )
};

export const CHECK_FOR_UPDATES_INTERVAL_IN_MS = 3600000;

export const RECEIVE_TRANSFER_PAGE_STATE = {
  BEEP_UNITLOADS: 'beep-unitloads'
};

export const RECEIVE_REDISPATCH_PAGE_STATE = {
  BEEP_PACKAGES: 'beep-packages',
  SELECT_CAUSE: 'select-cause',
  SELECT_DESTINATION: 'select-destination'
};

export const OPERATIONAL_PROCESS = {
  DISTRIBUTE: 'OPERATIONAL_PROCESS_DISTRIBUTE',
  RECEIVE: 'OPERATIONAL_PROCESS_RECEIVE',
  ADD_BAG_TO_TRANSFER: 'OPERATIONAL_PROCESS_ADD_BAG_TO_TRANSFER',
  START_TRANSFER: 'OPERATIONAL_PROCESS_START_TRANSFER',
  UNITIZE: 'OPERATIONAL_PROCESS_UNITIZE',
  SORT: 'OPERATIONAL_PROCESS_SORT',
  DISPATCH: 'OPERATIONAL_PROCESS_DISPATCH',
  IDENTIFY: 'OPERATIONAL_PROCESS_IDENTIFY',
  EDIT_PACKAGE_STATUS: 'OPERATIONAL_PROCESS_EDIT_PACKAGE_STATUS',
  PREPARE: 'OPERATIONAL_PROCESS_PREPARE',
  BEEP_LATENCY: 'OPERATIONAL_PROCESS_BEEP_LATENCY',
  DECLARE_DAMAGE: 'OPERATIONAL_PROCESS_DECLARE_DAMAGE',
  DECLARE_DAMAGE_V2: 'OPERATIONAL_PROCESS_DECLARE_DAMAGE_V2'
};

export const ACTIVITY = {
  FULL_PROCESS: 'FULL_PROCESS',
  BEEP_CONTEXT: 'BEEP_CONTEXT',
  BEEP_BAG: 'BEEP_BAG',
  BEEP_PACKAGE: 'BEEP_PACKAGE',
  BEEP_DESTINATION: 'BEEP_DESTINATION',
  WAITING_FOR_DRIVER: 'WAITING_FOR_DRIVER',
  REJECTED_PACKAGE_BEEP: 'REJECTED_PACKAGE_BEEP',
  CARA_CRACHA: 'CARA_CRACHA',
  DC_LIST: 'DC_LIST',
  TRANSFER_LIST: 'TRANSFER_LIST',
  BEEP_UNIT_LOAD: 'BEEP_UNIT_LOAD',
  BEEP_NEW_UNIT_LOAD: 'BEEP_NEW_UNIT_LOAD',
  INITIAL_IDENTIFICATION: 'INITIAL_IDENTIFICATION',
  INCOMPLETE_RECEIVE: 'INCOMPLETE_RECEIVE',
  SUSPICIOUS_PACKAGE: 'SUSPICIOUS_PACKAGE',
  CAUSE_SELECTION: 'CAUSE_SELECTION',
  RECEIVE_TRANSFER: 'RECEIVE_TRANSFER',
  RECEIVE_REDISPATCH: 'RECEIVE_REDISPATCH',
  COMMON_RECEIVE: 'COMMON_RECEIVE',
  RECEIVE_COUNTER: 'RECEIVE_COUNTER',
  RECEIVED_PACKAGES: 'RECEIVED_PACKAGES',
  MOVE_PACKAGES_BULK: 'MOVE_PACKAGES_BULK',
  BAG_UNKNOWN_ORIGIN: 'BAG_UNKNOWN_ORIGIN',
  SORT_CONTEXT_BEEP: 'SORT_CONTEXT_BEEP',
  SORT_BEEP: 'SORT_BEEP',
  SORT_CONFIRMATION_BEEP: 'SORT_CONFIRMATION_BEEP',
  DECLARE_DAMAGE_BEEP: 'DECLARE_DAMAGE_BEEP',
  DISTRIBUTE_BAG_BEEP: 'DISTRIBUTE_BAG_BEEP',
  DISTRIBUTE_PACKAGE_BEEP: 'DISTRIBUTE_PACKAGE_BEEP',
  DISTRIBUTE_CARA_CRACHA_BEEP: 'DISTRIBUTE_CARA_CRACHA_BEEP',
  DISTRIBUTE_STORE_BEEP: 'DISTRIBUTE_STORE_BEEP',
  DISTRIBUTE_FINISH_BEEP: 'DISTRIBUTE_FINISH_BEEP',
  DISTRIBUTE_TRIP_UNIT_BEEP: 'DISTRIBUTE_TRIP_UNIT_BEEP',
  DISTRIBUTE_TRIP_CONFIRMATION_BEEP: 'DISTRIBUTE_TRIP_CONFIRMATION_BEEP',
  DISTRIBUTE_START_TRIP_BEEP: 'DISTRIBUTE_START_TRIP_BEEP',
  DISTRIBUTE_GET_TRIP_BEEP: 'DISTRIBUTE_GET_TRIP_BEEP',
  ADD_BAG_TO_TRANSFER_BEEP: 'ADD_BAG_TO_TRANSFER_BEEP',
  ADD_BAG_TO_TRANSFER_SUMARY_BEEP: 'ADD_BAG_TO_TRANSFER_SUMARY_BEEP',
  START_TRANSFER_BAG_BEEP: 'START_TRANSFER_BAG_BEEP',
  START_TRANSFER_INIT_BEEP: 'START_TRANSFER_INIT_BEEP',
  IDENTIFY_BEEP: 'IDENTIFY_BEEP',
  ORGANIZE_STORAGE_BEEP: 'ORGANIZE_STORAGE_BEEP',
  ORGANIZE_UNIT_BEEP: 'ORGANIZE_UNIT_BEEP',
  ORGANIZE_UNITIZE_BEEP: 'ORGANIZE_UNITIZE_BEEP',
  ORGANIZE_MOVE_BULK_BEEP: 'ORGANIZE_MOVE_BULK_BEEP',
  PREPARE_PACKAGE_BEEP: 'PREPARE_PACKAGE_BEEP',
  CHANGE_DIRECTION_BEEP: 'CHANGE_DIRECTION_BEEP',
  CHANGE_LABEL_OLD_BEEP: 'CHANGE_LABEL_OLD_BEEP',
  CHANGE_LABEL_NEW_BEEP: 'CHANGE_LABEL_NEW_BEEP',
  DISPATCH_BEEP: 'DISPATCH_BEEP',
  EDIT_PACKAGE_STATUS_BEEP: 'EDIT_PACKAGE_STATUS_BEEP',
  UNITIZE_BEEP: 'UNITIZE_BEEP',
  INITIAL_IDENTIFICATION_BEEP: 'INITIAL_IDENTIFICATION_BEEP',
  RECEIVE_TRANSFER_BEEP: 'RECEIVE_TRANSFER_BEEP',
  MOVE_PACKAGES_BULK_BEEP: 'MOVE_PACKAGES_BULK_BEEP',
  RECEIVE_DESTINATION_BEEP: 'RECEIVE_DESTINATION_BEEP',
  RECEIVE_PACKAGE_BEEP: 'RECEIVE_PACKAGE_BEEP',
  RECEIVE_REDISPATCH_PACKAGE_BEEP: 'RECEIVE_REDISPATCH_PACKAGE_BEEP',
  RECEIVE_COUNTER_BEEP: 'RECEIVE_COUNTER_BEEP',
  FORM_FIRST_STEP: 'FORM_FIRST_STEP',
  FORM_SECOND_STEP: 'FORM_SECOND_STEP',
  UPLOAD_DAMAGE_PACKAGE_STEP: 'UPLOAD_DAMAGE_PACKAGE_STEP',
  MATCH_IMAGE_STEP: 'MATCH_IMAGE_STEP',
  FORM_STEP: 'FORM_STEP',
  DISTRIBUTE_GET_POSSIBLE_DESTINATIONS: 'DISTRIBUTE_GET_POSSIBLE_DESTINATIONS',
  DISTRIBUTION_UNIT_LOAD: 'DISTRIBUTION_UNIT_LOAD',
  DISTRIBUTION_BIPAGE_UNIT_LOAD: 'DISTRIBUTION_BIPAGE_UNIT_LOAD',
  START_TRANSFER: 'START_TRANSFER',
  DISTRIBUTE_PARTNER_CONTEXT_READER: 'DISTRIBUTE_PARTNER_CONTEXT_READER',
  DISTRIBUTE_PARTNER_BAG_PACKAGE_BEEP: 'DISTRIBUTE_PARTNER_BAG_PACKAGE_BEEP',
  DISTRIBUTE_PARTNER_LICENSE_PLATE_READ:
    'DISTRIBUTE_PARTNER_LICENSE_PLATE_READ',
  DISTRIBUTE_PARTNER_CUSTODY_EXCHANGE: 'DISTRIBUTE_PARTNER_CUSTODY_EXCHANGE',
  DISTRIBUTE_FORCE_CARGO_DESTINATION: 'DISTRIBUTE_FORCE_CARGO_DESTINATION'
};

export const DISTRIBUTE_STEP_TYPE = {
  SKIP_PACKAGES_CHECKS: 'DISTRIBUTE_STEP_TYPE_PACKAGES_CHECKS'
};

export const PACKAGE_DIRECTION_OPTIONS = [
  {
    id: 1,
    name: 'Direção não definida',
    shortName: 'Não definida',
    value: 'PACKAGE_DIRECTION_INVALID',
    changeAvailability: false
  },
  {
    id: 2,
    name: 'Direção de entrega',
    shortName: 'Entregar',
    value: 'PACKAGE_DIRECTION_DELIVERY',
    changeAvailability: true
  },
  {
    id: 3,
    name: 'Direção de devolução',
    shortName: 'Devolver',
    value: 'PACKAGE_DIRECTION_RETURN',
    changeAvailability: true
  }
];

export const PACKAGE_DIRECTION_REASONS = [
  {
    id: 0,
    name: '--',
    value: null
  },
  {
    id: 1,
    name: 'Mudança forçada',
    value: 'PACKAGE_DIRECTION_REASON_FORCED'
  },
  {
    id: 2,
    name: 'Fora de cobertura',
    value: 'PACKAGE_DIRECTION_REASON_OUT_OF_COVERAGE'
  },
  {
    id: 3,
    name: 'Endereço errado',
    value: 'PACKAGE_DIRECTION_REASON_WRONG_ADDRESS'
  },
  {
    id: 4,
    name: 'Recusado pelo cliente',
    value: 'PACKAGE_DIRECTION_REASON_REFUSED_BY_THE_RECIPIENT'
  },
  {
    id: 5,
    name: 'Cancelado pelo embarcador',
    value: 'PACKAGE_DIRECTION_REASON_CANCELED_BY_THE_SELLER'
  },
  {
    id: 6,
    name: 'Cliente ausente',
    value: 'PACKAGE_DIRECTION_REASON_ABSENT_RECIPIENT'
  },
  {
    id: 7,
    name: 'Excedido número de tentativas de entrega',
    value: 'PACKAGE_DIRECTION_REASON_DELIVERY_ATTEMPTS_EXCEEDED'
  },
  {
    id: 8,
    name: 'Excedido tempo de pacote sem arquivo',
    value: 'PACKAGE_DIRECTION_REASON_MISSING_FILE_TIME_EXCEEDED'
  },
  {
    id: 9,
    name: 'Outro',
    value: 'PACKAGE_DIRECTION_REASON_INVALID'
  }
];

export const IDENTIFY_EDIT_OPTIONS = [
  {
    id: 1,
    name: 'Status do pacote',
    component: '/identify/edit-package-status'
  },
  {
    id: 2,
    name: 'Direção do pacote',
    component: '/identify/change-direction'
  }
];

export const MIN_ATTEMPTS_TO_CONTINUE_SUSPICIOUS_CARA_CRACHA = 3;

export const VEHICLE_LICENSE_PLATE_LENGTH = 7;

export const UNIFIED_RECEIVE_UNIT_LOAD_SUFFIX = 'COL 01';

export const HTTP_STATUS_CODES = {
  OK: 200,
  CREATED: 201,
  ACCEPTED: 202,
  NO_CONTENT: 204,
  BAD_REQUEST: 400,
  FORBIDDEN: 403,
  NOT_FOUND: 404,
  REQUEST_TIMEOUT: 408,
  CONFLICT: 409,
  INTERNAL_SERVER_ERROR: 500
};

export const PACKAGE_LABEL_BY_STATUS = {
  [PACKAGE_STATUS_CODE.PACKAGE_STATUS_CODE_UNAVAILABLE]: {
    style: { color: colors.grey[900] },
    text: 'Não disponivel'
  },
  [PACKAGE_STATUS_CODE.PACKAGE_STATUS_CODE_TRANSFER_COMPLETED]: {
    style: { color: colors.blue[500] },
    text: 'Transferência finalizada'
  },
  [PACKAGE_STATUS_CODE.PACKAGE_STATUS_CODE_RECIPIENT_UNAVAILABLE]: {
    style: { color: colors.grey[300] },
    text: 'Destinatário ausente'
  },
  [PACKAGE_STATUS_CODE.PACKAGE_STATUS_CODE_RECIPIENT_REFUSED]: {
    style: { color: colors.grey[600] },
    text: 'Recusado pelo destinatário'
  },
  [PACKAGE_STATUS_CODE.PACKAGE_STATUS_CODE_INVALID_DESTINATION]: {
    style: { color: colors.grey[600] },
    text: 'Endereço errado'
  },
  [PACKAGE_STATUS_CODE.PACKAGE_STATUS_CODE_DELIVERED]: {
    style: { color: colors.green[500] },
    text: 'Entregue'
  },
  [PACKAGE_STATUS_CODE.PACKAGE_STATUS_CODE_DAMAGED]: {
    style: { color: colors.blueGrey[900] },
    text: 'Mercadoria avariada'
  },
  [PACKAGE_STATUS_CODE.PACKAGE_STATUS_CODE_LOST]: {
    style: { color: colors.blueGrey[900] },
    text: 'Pacote extraviado'
  },
  [PACKAGE_STATUS_CODE.PACKAGE_STATUS_CODE_STOLEN]: {
    style: { color: colors.blueGrey[900] },
    text: 'Roubo / Furto'
  },
  [PACKAGE_STATUS_CODE.PACKAGE_STATUS_CODE_AWAITING_RESOLUTION]: {
    style: { color: colors.blueGrey[900] },
    text: 'Aguardando tratativa'
  },
  [PACKAGE_STATUS_CODE.PACKAGE_STATUS_CODE_READY_TO_RESEND]: {
    style: { color: colors.blueGrey[900] },
    text: 'Aguardando reenvio'
  },
  [PACKAGE_STATUS_CODE.PACKAGE_STATUS_CODE_READY_TO_RETURN]: {
    style: { color: colors.blueGrey[900] },
    text: 'Aguardando devolução'
  },
  [PACKAGE_STATUS_CODE.PACKAGE_STATUS_CODE_CONFIRMED]: {
    style: { color: colors.blueGrey[900] },
    text: 'Conferido'
  },
  [PACKAGE_STATUS_CODE.PACKAGE_STATUS_CODE_CUSTOMER_PICKUP]: {
    style: { color: colors.blueGrey[900] },
    text: 'Coletado no CD do cliente'
  },
  [PACKAGE_STATUS_CODE.PACKAGE_STATUS_CODE_PICKUP]: {
    style: { color: colors.blue[500] },
    text: 'Retirado'
  },
  [PACKAGE_STATUS_CODE.PACKAGE_STATUS_CODE_GOING_TO_DELIVER]: {
    style: { color: colors.blue[500] },
    text: 'Em rota de entrega'
  }
};

export const STRING_NULL = 'null';

export const STRING_UNDEFINED = 'undefined';

export const NO_PROCESSING_DAEADLINE = 'sem prazo definido';

export const ROUTING_CODE_SENTRY_TAG = 'routing_code';

export const REJECT = 'REJ';

// R$ 5000
export const BAG_AVERAGE = 5000;

export const CONSOLIDATION_TYPE_OPTIONS = [
  {
    id: 0,
    name: 'Tipo de consolidação não definida',
    shortName: 'Não definida',
    value: 'RETURN_CONSOLIDATION_TYPE_INVALID'
  },
  {
    id: 1,
    name: 'Devolução não consolidada',
    shortName: 'Não consolidada',
    value: 'RETURN_CONSOLIDATION_TYPE_NO_CONSOLIDATION'
  },
  {
    id: 2,
    name: 'Devolução consolidada',
    shortName: 'Consolidada',
    value: 'RETURN_CONSOLIDATION_TYPE_CONSOLIDATE_BY_SHIPPER'
  }
];

export const SURVEY = {
  STATES: {
    CONFIRMATION: 'CONFIRMATION',
    SURVEY: 'SURVEY',
    CLOSE: ''
  },
  LABELS: {
    TITLE: 'Como está o aplicativo?',
    SUBTITLE: 'Avalie a experiência. Leva só 2 minutinhos.',
    CONFIRM: 'Avaliar',
    CANCEL: 'Pular'
  },
  STORAGE_STATES: {
    SUBMITTED: 'sort_survey_submitted',
    CLOSED: 'sort_survey_closed'
  },
  CASH_TIME: 86400000 // 24h in ms
};

export const CANCEL_DISTRIBUTION_SUBTITLE =
  'Se você descartar, as unidade bipadas não farão mais parte da transferência.';

export const ERROR_MESSAGE_UPDATE_VEHICLE_UNIT_LOAD =
  'Erro ao atualizar o tipo de transporte.';

export const BUTTON_LABEL_TEXT = {
  CONFIRM: 'Pronto, recebidos'
};

export const RECEIVE_MESSAGES = {
  DUPLICATED_BEEP: 'Opa, unidade já bipada!',
  RECEIVE_SUCCESS: 'Pronto, tudo certo.\nUnidades recebidas.',
  NOT_FOUND: 'Código não encontrado',
  PACKAGE_NOT_BELONG: 'Unidade já foi recebida e movimentada.',
  DATA_EMPTY: 'Tente novamente'
};
